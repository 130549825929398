<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: this.$route.path,
      isMobile: true
    }
  },
  mounted() {
    document.title = '枣强复材指数'
    this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    if (this.isMobile) {
      window.location.href = window.location.origin + '/' + 'mobile' + window.location.hash
    }
    window.onresize = () => {
      return (() => {
        this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
        if (this.isMobile) {
          window.location.href = window.location.origin + '/' + 'mobile' + window.location.hash
        }
      })()
    };
  },
  methods: {
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #eeeff4;

}
</style>
