import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect:'/index',
    component:()=>import('@/views/index.vue'),
    children:[
      {
        path:'/index',
        name:'index',
        component:()=>import('@/views/home/index.vue'),
        meta:{title:'首页'}
      },
      {
        path:'/data',
        name:'data',
        component:()=>import('@/views/data/index.vue'),
        meta:{title:'指数数据'}
      },
      {
        path:'/comment',
        name:'comment',
        component:()=>import('@/views/comment/index.vue'),
        meta:{title:'指数评论'}
      },
      {
        path:'/comment/:id',
        name:'comment',
        component:()=>import('@/views/comment/detail.vue'),
        meta:{title:'指数评论'}
      },
      {
        path:'/enter',
        name:'enter',
        component:()=>import('@/views/enter/index.vue'),
        meta:{title:'企业展示'}
      },
      {
        path:'/enter/:id',
        name:'enter',
        component:()=>import('@/views/enter/detail.vue'),
        meta:{title:'企业展示'}
      },
      {
        path:'/product',
        name:'product',
        component:()=>import('@/views/product/index.vue'),
        meta:{title:'精品推荐'}
      },
      {
        path:'/product/:id',
        name:'product',
        component:()=>import('@/views/product/index.vue'),
        meta:{title:'精品推荐'}
      },
      {
        path:'/info',
        name:'info',
        component:()=>import('@/views/info/index.vue'),
        meta:{title:'新闻资讯'}
      },
      {
        path:'/info/:id',
        name:'info',
        component:()=>import('@/views/info/detail.vue'),
        meta:{title:'新闻资讯'}
      },
      {
        path:'/introduce',
        name:'introduce',
        component:()=>import('@/views/introduce/index.vue'),
        meta:{title:'指数介绍'}
      },
      {
        path:'/about',
        name:'about',
        component:()=>import('@/views/about/index.vue'),
        meta:{title:'关于我们'}
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  document.title='枣强复合材料产业指数'
  next()
})
export default router
